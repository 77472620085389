import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import Mailjet from "node-mailjet";

export const ContactContainer = styled.div` 
    width: 100%;
    min-height: calc(80vh - 70px);
    position: relative;
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;   
    align-items: center;
`;

export const FormText = styled.div``;

export const Text = styled.h3`
    overflow: hidden;
    color: white;
    font-weight: bold;
`;

export const FooterContainer = styled.div`
    width: 100%;
    height: 70px;
    position: relative;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const FooterText = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    color: #b0b0b0;
`;

export const FootText = styled.div`
    font-size: 10px;
`;

export default function Contact() {
    function sendEmail(e) {
        e.preventDefault();
    
        const formData = new FormData(e.target);
        const data = {
            userName: formData.get("user_name"),
            userEmail: formData.get("user_email"),
            message: formData.get("message"),
        };
    
        fetch("https://api.donerightohd.net/send-email", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => console.log("Success:", data))
            .catch(error => console.error("Error:", error));
    
        e.target.reset();
    }    

    return (
        <ContactContainer id="contact">
            <FormContainer>
                <Text>Contact Us</Text>
                <form onSubmit={sendEmail}>
                    <div className="row pt-5 mx-auto">
                        <FormText>
                            <div className="col-15 form-group mx-auto">
                                <input type="text" className="form-control" placeholder="Name" name="user_name" />
                            </div>
                            <div className="col-15 form-group pt-2 mx-auto">
                                <input type="email" className="form-control" placeholder="Email Address" name="user_email" />
                            </div>
                            <div className="col-15 form-group pt-2 mx-auto">
                                <textarea className="form-control" cols="10" rows="4" placeholder="Your message" name="message"></textarea>
                            </div>
                        </FormText>
                        <div className="col-15 pt-3 mx-auto">
                            <input type="submit" className="btn btn-info" value="Send Message" />
                        </div>
                    </div>
                </form>
            </FormContainer>
        </ContactContainer>
    );
};
